.component-main_banner_2 {
  position: relative;
  width: 100%;
  z-index: 9;
  overflow: hidden;

  .after {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-image: url("./assets/img-bg-mobile.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .navigate-button {
    font-size: 19px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
  }

  .form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, .7), rgba(255, 255, 255, .5));
    border: 1px solid #fff;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .wrapper-2 {
    margin-top: 40px;

    .rich-text {
      color: #fff;
    }

    .form-wrapper {
      margin-top: 10px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9));
    }
  }

  .wrapper-3 {
    &.form-wrapper {
      background: linear-gradient(to bottom, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9));
    }
  }

  .inputs {
    width: 100%;
    margin: 15px 0;

    .input {
      margin-bottom: 16px;

      input {
        font-size: 16px;
        padding: 15px 10px;
        width: 100%;
        border: 0;
        border-bottom: 2px solid #DCDCEC;
        background: transparent;
      }
    }
  }

  .wrapper-3 {
    .inputs {
      input {
        border: 0;
        padding: 15px 15px;
        border-radius: 30px;
        background: #fff;
      }
    }
  }

  .button {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 7px;
    min-width: 144px;
    max-width: 390px;
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    margin: 0 5px;
  }

  .button.primary {
    background: #FF004E;
  }

  .button.secondary {
    background: #0430D5;
  }

  .button.gradient {
    background: #FFBB00;
    background: linear-gradient(78deg, #FFBB00 0%, #FF6F00 100%);
    width: 100%;
  }

  .bannerContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .bannerContent .topBanner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
    box-sizing: border-box;
    padding: 0px 30px;
  }

  .bannerContent .topBanner.hd {
    z-index: 10000;
  }

  .bannerContent .topBanner.place-center {
    align-items: center;
  }

  .bannerContent .topBanner.place-right {
    align-items: flex-end;
  }


  .bannerContent .topBanner .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: transparent;
    padding: 15px 0px;
  }

  .bannerContent .topBanner .header .logo {
    cursor: pointer;
    position: relative;
    width: 100%;
    text-decoration: none;
    margin-bottom: 22px;
    height: 41px;
    transition: all .2s ease-in-out;
  }

  .bannerContent .topBanner .header .logo img {
    width: 169px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    transition: all .2s ease-in-out;
  }

  .bannerContent .topBanner .header.fixedHeader {
    position: fixed;
    background-color: rgba(6, 0, 91, 0.9);
    background-color: linear-gradient(180deg, #06005b 0%, #06005b 100%);
    padding: 10px 0px;
  }

  .bannerContent .topBanner .header.fixedHeader .logo {
    margin-bottom: 12px;
    height: 34px;
  }

  .bannerContent .topBanner .header.fixedHeader .logo img {
    width: 139px;
  }

  .bannerContent .topBanner .header.fixedHeader .topButtons a {
    padding: 6px 20px;
  }

  .bannerContent .topBanner .header .topButtons {
    display: flex;
    width: 100%;
    max-width: 344px;
    justify-content: space-between;
    align-items: flex-start;
  }

  .bannerContent .topBanner .header .topButtons a {
    transition: all .2s ease-in-out;
  }

  .bannerContent .topBanner .bannerTitle {
    color: #FFFFFF;
    font-size: 80px;
    text-align: center;
    width: 100%;
    margin: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }

  .bannerContent .topBanner .bannerTitle.m150 {
    margin-top: 200px
  }

  .bannerContent .topBanner .bannerSubTitle {
    color: #FFFFFF;
    font-size: 45px;
    text-align: center;
    align-self: flex-start;
    margin: 0px;
    width: 100%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }

  .bannerContent .topBanner .bannerSubTitle.last {
    margin-bottom: 45px;
  }

  .bannerContent .topBanner .button {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }

  .bannerContent .topBanner>.button {
    //margin-top: 240px;
  }

  .itemsWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;



    .navItem {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: calc(100% - 40px);
      margin: 20px 20px 0;
      padding: 40px 70px 0px 70px;
      box-sizing: border-box;
      position: relative;
      overflow: visible;
      padding-bottom: 5px;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 329px;
        margin: 0 auto;
      }
    }

    .navItem .navItemContent {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      position: relative;
    }

    .navItem .navItemContent .number {
      border: 3px solid #ffffff;
      border-radius: 100%;
      width: 32px;
      min-width: 32px;
      height: 32px;
      text-align: center;
    }

    .navItem .navItemContent .number span {
      font-size: 26px;
      color: #ffffff;
      text-align: center;
    }

    .navItem .navItemContent .description {
      max-width: 80%;
      width: fit-content;
      margin: 0 0px 0px 10px;
    }

    .navItem .navItemContent .description .navTitle {
      margin: 0;
      font-size: 24px;
      color: #ffffff;
      min-width: 150px;
      white-space: nowrap;
    }

    .navItem .navItemContent .description .navSubTitle {
      margin: 5px 0px 0px 0px;
      font-size: 14px;
      color: #CCCCCF;
      line-height: 1.5;
    }

    &.type-2 {
      .navItem {
        align-items: center;
        justify-content: center;
        padding: 20px 20px 20px 20px;

        .navItemContent .description .navTitle {
          font-size: 33px;
          font-weight: bold;
        }

        .navItemContent .description .navSubTitle {
          font-size: 22px;
        }

        svg {
          display: none;
        }
      }
    }

    &.type-3 {
      .navItem {
        background: rgba(255, 255, 255, .9);
        border-radius: 10px;
        padding: 20px 60px 20px 60px;

        .navItemContent .number {
          border: 0;
          border-radius: 0;
          width: 47px;
          min-width: 47px;
          height: auto;
          text-align: center;
        }

        .navItemContent .number span {
          font-size: 82px;
          color: #2E3192;
          font-weight: bold;
          text-align: center;
          line-height: 60px;
        }

        .navItemContent .description .navTitle {
          color: #111127;
        }

        .navItemContent .description .navSubTitle {
          color: #5F5F6E;
        }

        svg {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 813px) {
    .after {
      background-image: url("./assets/img-bg.png");
    }

    .bannerContent {
      align-items: center;
      width: 100%;
    }

    .form-wrapper {
      margin-top: 90px;
      margin-bottom: 78px;
    }

    .bannerContent .topBanner {
      align-items: flex-start;
      max-width: 1140px;
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
    }

    .bannerContent .topBanner .header {
      flex-direction: row;
      justify-content: space-between;
      position: relative !important;
      background-color: transparent !important;
      padding: 0px;
    }

    .bannerContent .topBanner .header .logo {
      width: auto;
      text-decoration: none;
      margin-bottom: 0;
      height: 41px;
    }

    .bannerContent .topBanner .header .logo img {
      position: relative;
      left: 0;
      margin: 0;
    }

    .bannerContent .topBanner .header .topButtons {
      max-width: 320px;
    }

    .bannerContent .topBanner .header.fixedHeader {
      padding: 0;
    }

    .bannerContent .topBanner .header.fixedHeader .topButtons a {
      padding: 12px 20px;
    }

    .bannerContent .topBanner .header.fixedHeader .logo {
      height: 41px;
      margin-bottom: 0px;
    }

    .bannerContent .topBanner .header.fixedHeader .logo img {
      width: 169px;
    }

    .bannerContent .topBanner>.button {
      margin-top: 20px;
      max-width: 320px;
    }

    .itemsWrapper {
      flex-direction: row;
      margin-bottom: 0;

      .navItem {
        justify-content: center;
        align-items: center;
        margin: 0;
        height: 126px;
        padding: 0;
        width: calc(1140px / 3);

        svg {
          max-width: none;
          margin: unset;
        }
      }

      .navItem .navItemContent .description .navTitle {
        font-size: 28px;
      }

      .navItem .navItemContent .description .navSubTitle {
        font-size: 16px;
      }

      &.type-3 {
        .navItem {
          margin-right: 30px;
        }

        .navItem:last-child {
          margin-right: 0;
        }
      }
    }
  }

}
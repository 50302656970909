@use "./styles/reset.scss" as *;
@use "./styles/fonts.scss" as *;
@use "./styles/flexUtils.scss" as *;
@use "./components/_common/style.scss" as *;

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a>img {
  pointer-events: none;
}
.component-terms_and_conditions_1 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  overflow: hidden;

  .collapse {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    padding: 20px 0;
    width: fit-content;
    border: none;
    text-align: left;
    outline: none;
    pointer-events: all !important;
  }

  .collapse p {
    margin-left: 10px;
    color: rgba(231, 231, 239, 0.9);
    font-size: 16px;
    font-weight: 500;
    transition: color .2s ease-in-out;
  }

  .collapse .Open,
  .collapse .Close {
    width: 21px;
    height: 21px;
    opacity: .8;
    transition: opacity .2s ease-in-out;
  }

  .collapse .Open {
    display: block;
  }

  .collapse .Close {
    display: none;
  }

  .collapse.active .Open {
    display: none;
  }

  .collapse.active .Close {
    display: block;
  }

  .collapse.active .Open,
  .collapse.active .Close {
    opacity: 1;
  }

  .collapse.active p {
    color: white;
  }

  .collapse:hover .Open,
  .collapse:hover .Close {
    opacity: 1;
  }

  .collapse:hover p {
    color: white;
  }

  .tcContent {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    color: rgba(231, 231, 239, 0.9);
  }

  @media screen and (min-width: 813px) {
    max-width: 1140px;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    margin: 0 auto;
  }

}
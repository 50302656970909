.component-youtube_component {
  .text-title {
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 54px;
  }

  .iframe-wrapper {
    display: flex;
    justify-content: center;
  }

  iframe {
    border: 3px solid #FFFFFF;
    border-radius: 13px;
    max-width: 1014px;
    max-height: 572px;

    width: 100%;
    margin: 0 auto;
  }

  @media screen and (min-width: 813px) {
    max-width: 1140px;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    margin: 0 auto;
  }
}
.component-carousel {
  width: 100%;
  max-width: 1140px;

  margin: 0 auto;
  box-sizing: border-box;

  .carousel-wrapper {
    padding: 40px;
    display: flex;
    overflow: visible;
  }

  .carousel-title {
    color: #fff;
    margin: 20px 10px 10px;
    display: inline-block;
  }

  .carousel-cell {
    width: 190px;
    height: 230px;
    margin-right: 15px;
    border-radius: 20px;
    counter-increment: gallery-cell;
    background: #ff54c6;
    background: linear-gradient(321deg, #ff54c6 0%, #a64ffc 100%);
    padding: 4px;
    box-sizing: border-box;

    a {
      img {
        pointer-events: none;
      }
    }
  }

  .carousel-cell .cell-content {
    background: #f7deff;
    background: linear-gradient(322deg, #f7deff 0%, white 50%, #ffd5f0 100%);
    width: 182px;
    height: 222px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flickity-button {
    position: absolute;
    background: rgba(103, 40, 184, 0.7);
    border: none;
    color: rgba(255, 255, 255, 0.7);
    transition: all .2s ease-in-out;
  }

  .flickity-button:hover {
    background: #6728b8;
    border: none;
    color: white;
  }

  .flickity-viewport {
    width: 100%;

  }


  @media screen and (min-width: 813px) {}

}
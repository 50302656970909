.component-footer {
  background: #151D26;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;

  .footerTop {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .footerTop .footerImages {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80px;
  }

  .footerTop .footerImages img {
    margin-right: 10px;
    width: 31px;
    height: 31px;
  }

  .footerTop .footerDescription {
    width: fit-content;
    font-size: 11px;
    color: #FFFFFF;
    line-height: 1.8;
    margin: 0;
  }

  .footerText {
    font-size: 11px;
    color: #FFFFFF;
    line-height: 1.8;
    margin: 0;
  }


  @media screen and (min-width: 813px) {
    display: flex;
    justify-content: center;
    align-items: center;

    .footerContent {
      max-width: 1140px;
      width: 100%;
    }

    .footerContent .footerTop .footerImages {
      width: 140px;
      margin-right: 15px;
    }

    .footerContent .footerTop .footerImages img {
      margin-right: 15px;
      width: 61px;
      height: 61px;
    }

    .footerContent .footerTop .footerDescription {
      max-width: 500px;
      width: fit-content;
      font-size: 13px;
      color: #FFFFFF;
      line-height: 1.8;
      margin: 0;
    }

    .footerContent .footerText {
      font-size: 13px;
      color: #FFFFFF;
      line-height: 1.8;
      margin: 0;
    }
  }

}
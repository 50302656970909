.component-nav_bar_1 {

  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -59px;
  overflow: hidden;

  .lastArrow {
    width: 100%;
    height: 59px;
    position: relative;
    z-index: 5;
  }

  .lastArrow .before {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-style: solid;
    border-width: 59px calc(98vw / 2) 0 calc(98vw / 2);
    border-color: #FF7000 transparent transparent transparent;
  }

  .navItem {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 40px 70px 0px 70px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }

  .navItem:nth-child(1) {
    background: #FF9900;
  }

  .navItem:nth-child(2) {
    background: #FF7F00;
    padding-top: 80px;
  }

  .navItem.second .before {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-style: solid;
    border-width: 59px calc(98vw / 2) 0 calc(98vw / 2);
    border-color: #FF9900 transparent transparent transparent;
  }

  .navItem:nth-child(3) {
    background: #FF7000;
    padding-top: 80px;
  }

  .navItem.third .before {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-style: solid;
    border-width: 59px calc(98vw / 2) 0 calc(98vw / 2);
    border-color: #FF7F00 transparent transparent transparent;
  }

  .navItem .navItemContent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .navItem .navItemContent .number {
    border: 3px solid #ffffff;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    text-align: center;
  }

  .navItem .navItemContent .number span {
    font-size: 26px;
    color: #ffffff;
    text-align: center;
  }

  .navItem .navItemContent .description {
    max-width: 80%;
    width: fit-content;
    margin: 5px 0px 0px 10px;
  }

  .navItem .navItemContent .description .navTitle {
    margin: 0;
    font-size: 24px;
    color: #ffffff;
    min-width: 150px;
  }

  .navItem .navItemContent .description .navSubTitle {
    margin: 5px 0px 0px 0px;
    font-size: 14px;
    color: #ffffff;
    line-height: 1.5;
  }

  @media screen and (min-width: 813px) {
    flex-direction: row;
    background: #FF9900;
    background: linear-gradient(to right, #FF9900 0%, #FF9900 50%, #FF7000 50%, #FF7000 100%);
    margin-bottom: 0;

    .lastArrow {
      display: none;
    }

    .navItem {
      justify-content: center;
      align-items: center;
      height: 126px;
      padding: 0px 0px 0px 50px;
      width: calc(1140px / 3);
    }

    .navItem:nth-child(1) {
      padding-top: 0;
      background: #FF9900;
    }

    .navItem:nth-child(2) {
      background: #FF7F00;
      padding-top: 0px;
    }

    .navItem.second .before {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 63px 0 63px 40px;
      border-color: transparent transparent transparent #FF9900;
    }

    .navItem:nth-child(3) {
      background: #FF7000;
      padding-top: 0px;
      margin-right: 40px;
    }

    .navItem.third .before {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 63px 0 63px 40px;
      border-color: transparent transparent transparent #FF7F00;
    }

    .navItem .navItemContent .description {
      margin: 8px 0px 0px 10px;
    }

    .navItem .navItemContent .description .navTitle {
      font-size: 20px;
    }

    .navItem .navItemContent .description .navSubTitle {
      display: none;
    }
  }

}
.component-wheel-banner {
  position: relative;
  width: 100%;
  height: 650px;
  z-index: 9;
  overflow: hidden;

  .after {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-image: url("./assets/img-bg-mobile-wheel.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .button {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 7px;
    min-width: 144px;
    max-width: 390px;
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
  }

  .button.primary {
    background: #FF004E;
  }

  .button.secondary {
    background: #0430D5;
  }

  .button.gradient {
    background: #FFBB00;
    background: linear-gradient(78deg, #FFBB00 0%, #FF6F00 100%);
    width: 100%;
    height: 65px;
    font-size: 22px;
    padding: 28px 20px;
    margin-top: 65px;
  }

  .bannerContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .bannerContent .cover {
    width: 100%;
    height: 100%;
    background-color: rgba(25, 25, 25, 0.1);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .wheelArea {
    justify-content: center;
    align-items: center;
    position: relative;
    width: 350px;
    height: 100%;
    margin-top: 40px;
    zoom: 85%;
    z-index: 2;

    .wheel_pin {
      position: absolute;
      z-index: -2;
    }

    .wheel_1 {
      position: absolute;
      z-index: -3;
      //animation: rotation 18s ease-out infinite;
      //animation-fill-mode: forwards;
    }

    .wheel_2 {
      position: absolute;
      z-index: -4;
      //animation: rotation 13s ease-out infinite;
      //animation-fill-mode: forwards;
      width: calc(100% - 15px);
    }

    .wheelShadow {
      position: absolute;
      width: 335px;
      height: 335px;
      border-radius: 100%;
      box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.6);
      z-index: -5;
    }

    .wheel_3 {
      position: absolute;
      z-index: -6;
      top: 360px;
      //animation: rotation 9s ease-out infinite;
      //animation-fill-mode: forwards;
    }

    .arrow_left {
      position: absolute;
      z-index: -1;
      top: 500px;
      left: 110px;
    }

    .arrow_center {
      position: absolute;
      z-index: -1;
      top: 20px;
    }

    .arrow_right {
      position: absolute;
      top: 500px;
      left: -20px;
    }

    .effect {
      position: absolute;
      z-index: -9;
      mix-blend-mode: lighten;
    }

    .wheel_bg_decorations {
      position: absolute;
      top: 160px;
      z-index: -4;
    }

    .hud {
      position: absolute;
      width: 852px;
      z-index: -10;
      margin-left: 70px;
      margin-top: 60px;
    }

  }

  .bannerContent .contentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    *>div {
      flex: 1 1 auto;
    }

    .wheelArea,
    .buttonsArea {
      display: none;
    }


  }

  .bannerContent .topBanner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    z-index: 3;
    box-sizing: border-box;
    padding: 0px 30px;
  }

  .bannerContent .topBanner.hd {
    z-index: 10000;
  }

  .bannerContent .topBanner.cnt {
    position: relative;
  }

  .wheelArea.mobile {
    display: flex;
    position: absolute;
    z-index: 0;
    width: 350px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50px);
  }

  .bannerContent .topBanner .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: absolute;
    background-color: transparent;
    top: 0px;
    transition: background-color .2s ease-in-out;
    padding: 15px 0px;
    z-index: 500;
  }

  .bannerContent .topBanner .header .logo {
    cursor: pointer;
    position: relative;
    width: 100%;
    text-decoration: none;
    margin-bottom: 22px;
    height: 41px;
    transition: all .2s ease-in-out;
  }

  .bannerContent .topBanner .header .logo img {
    width: 169px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    transition: all .2s ease-in-out;
  }



  .bannerContent .topBanner .header.fixedHeader {
    position: fixed;
    background-color: rgba(6, 0, 91, 0.9);
    background-color: linear-gradient(180deg, #06005b 0%, #06005b 100%);
    padding: 10px 0px;
  }

  .bannerContent .topBanner .header.fixedHeader .logo {
    margin-bottom: 12px;
    height: 34px;
  }

  .bannerContent .topBanner .header.fixedHeader .logo img {
    width: 139px;
  }

  .bannerContent .topBanner .header.fixedHeader .topButtons a {
    padding: 6px 20px;
  }

  .bannerContent .topBanner .header .topButtons {
    display: flex;
    width: 100%;
    max-width: 344px;
    justify-content: space-between;
    align-items: flex-start;
  }

  .bannerContent .topBanner .header .topButtons a {
    transition: all .2s ease-in-out;
  }

  .bannerContent .topBanner .bannerTitle {
    color: #FFFFFF;
    font-size: 80px;
    text-align: center;
    width: 100%;
    margin: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }

  .bannerContent .topBanner .bannerTitle.m150 {
    margin-top: 200px
  }

  .bannerContent .topBanner .bannerSubTitle {
    color: #FFFFFF;
    font-size: 45px;
    text-align: center;
    align-self: flex-start;
    margin: 0px;
    width: 100%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }

  .bannerContent .topBanner .bannerSubTitle.last {
    margin-bottom: 45px;
  }

  .bannerContent .topBanner .button {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }

  @media screen and (min-width: 1140px) {
    display: flex;
    justify-content: center;
    height: 600px;

    .bannerContent .topBanner .bannerTitle.m150 {
      margin-top: 108px;
    }


    .after {
      background-image: url("./assets/img-bg-wheel.png");
    }

    .bannerContent {
      align-items: center;
      width: 100%;
    }

    .bannerContent .cover {
      width: 100%;
      height: 100%;
      background-color: rgba(25, 25, 25, 0.1);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .bannerContent .topBanner.hd {
      max-width: 1140px;
      width: 100%;
      transform: translate(0, 0);
    }

    .bannerContent .contentWrapper {
      max-width: 1140px;
      width: 100%;
      justify-content: space-between;
    }

    .bannerContent .topBanner {
      align-items: flex-start;
      //max-width: 1140px;
      //width: 100%;
      padding: 0px;
      box-sizing: border-box;
    }

    .bannerContent .topBanner .header {
      flex-direction: row;
      justify-content: space-between;
      position: relative !important;
      background-color: transparent !important;
      padding: 0px;
    }

    .bannerContent .topBanner .header .logo {
      width: auto;
      text-decoration: none;
      margin-bottom: 0;
      height: 41px;
    }

    .bannerContent .topBanner .header .logo img {
      position: relative;
      left: 0;
      margin: 0;
    }

    .bannerContent .topBanner .header .topButtons {
      max-width: 320px;
    }

    .bannerContent .topBanner .header.fixedHeader {
      padding: 0;
    }

    .bannerContent .topBanner .header.fixedHeader .topButtons a {
      padding: 12px 20px;
    }

    .bannerContent .topBanner .header.fixedHeader .logo {
      height: 41px;
      margin-bottom: 0px;
    }

    .bannerContent .topBanner .header.fixedHeader .logo img {
      width: 169px;
    }

    .bannerContent .topBanner .bannerTitle,
    .bannerContent .topBanner .bannerSubTitle {
      max-width: 500px;
      text-align: left;
    }

    .bannerContent .topBanner>.button {
      margin-top: 20px;
      max-width: 320px;
    }
  }

  @media screen and (min-width: 1140px) {
    .bannerContent .topBanner .header {
      top: 10px;
    }

    .bannerContent .wheelArea.mobile {
      display: none;
    }

    .bannerContent .topBanner .bannerTitle.m150 {
      margin-top: 108px;
    }

    .button.gradient {
      height: 65px;
      padding: 28px 10px;
      margin-top: 0;
    }

    .bannerContent .contentWrapper {
      .topBanner {
        width: 395px;
      }

      .wheelArea {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 350px;
        height: 100%;
        margin-top: 0;
        zoom: normal;

        .wheel_3 {
          top: 200px;
        }

        .arrow_left {
          top: 345px;
          left: 110px;
        }

        .arrow_center {
          top: -115px;
        }

        .arrow_right {
          top: 345px;
          left: -30px;
        }


        .wheel_bg_decorations {
          top: 25px;
        }
      }

      .buttonsArea {
        width: 495px;
        display: block;
        margin: 0 auto;
        height: 100%;
        background-size: cover;
        background-position: center right;
        background-repeat: no-repeat;
        z-index: 3;
        margin-right: -100px;
      }
    }
  }

  @keyframes rotation {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
.component-row_blocks_1 {
  overflow: hidden;



  .HorizontalBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

  .HorizontalBlock .HBlockContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .HorizontalBlock .HBlockContainer .HBlockContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .HorizontalBlock .HBlockContainer .HBlockContent .HBlockSubText {
    color: #E7E7EF;
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 10px;
  }

  .HorizontalBlock .HBlockContainer .HBlockContent .HBlockLogo {
    display: block;
    height: 100px;
    width: auto;
    margin: 0 0 10px;
  }


  .HorizontalBlock .HBlockContainer .HBlockContent .HBlockTitle {
    position: relative;
    width: 100%;
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    margin: 0 0 30px 0;
  }

  .HorizontalBlock .HBlockContainer .HBlockContent .HBlockText {
    color: #E7E7EF;
    line-height: 2;
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
  }

  .HorizontalBlock .HBlockContainer .HBlockContent a.button {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 15px;
    height: auto;
    padding: 20px 20px;
  }

  .button {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 7px;
    min-width: 144px;
    max-width: 390px;
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
  }

  .button.gradient {
    background: #FFBB00;
    background: linear-gradient(78deg, #FFBB00 0%, #FF6F00 100%);
    width: 100%;
    height: 86px;
    font-size: 27px;
    padding: 28px 20px;
  }

  @media screen and (min-width: 813px) {
    .row_blocks_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: flex-start;
      flex-wrap: nowrap;
      box-sizing: border-box;
      max-width: 1140px;
      width: 100%;
      padding: 50px 30px;
      margin: 0 auto;
    }

    .HorizontalBlock {
      box-sizing: border-box;
      padding: 0px;
    }

    .HorizontalBlock:nth-child(2) {
      padding: 0px 30px;
    }

    .HorizontalBlock .HBlockContainer .HBlockContent .HBlockTitle {
      font-size: 33px;
      text-align: left;
      margin-bottom: 40px;
    }

    .HorizontalBlock .HBlockContainer .HBlockContent .HBlockText {
      font-size: 16px;
      text-align: left;
    }

    .HorizontalBlock .HBlockContainer .HBlockContent a.button {
      display: none;
    }
  }

}